import {post, get} from 'ys-admin'


/**
 * 登录
 * @param {Object} params
 * @returns {Object}
 */
export const login = function (params) {
  return post('/user/Login', params)
};
/**
 * 获取用户信息
 * @param {Object} params
 * @returns {Object}
 */
export const getInfo = function (params) {
  return get('/user/GetInfo',params)
};
/**
 *  获取专项任务检查风险详情
 * @param {Object} params
 * @returns {Object}
 */
export const getRiskInfo = function (params) {
  return get('/risk/GetInfo', params)
};
/**
 * 登录
 * @param {Object} params
 * @returns {Object}
 */
export const refreshToken = function (params) {
  return post('/auth/RefreshToken', params)
};
/**
 * 获取当前登录用户信息
 * @returns {Object}
 */
export const GetCurrentInfo = function () {
  return get('/user/GetInfo');
};
/**
 * 登录
 * @param {Object} params
 * @returns {Object}
 */
export const loginAuth = function (params) {
  return post('/auth/Login', params)
};
/**
 * 获取站点配置
 * @returns {Array}
 */
export const getSystemInfo = function () {
  return get('/systemConfig/GetSystemInfo')
};

/**
 * 获取检查任务字典明细列表
 * @param {Object} params
 * * @returns {Array}
 */
export const getDict = function (params) {
  return get('/resource/Dict',params)
};


/**
 * 获取区域字典项
 * @returns {Array}
 */
export const getAreaList = function () {
  return get('/resource/Area')
};

/**
 * 获取检查任务字典明细列表
 * @param {Object} params
 * * @returns {Array}
 */
export const dictList = function (params) {
  return get('/dict/List',params)
};

/**
 * 获取附件列表
 * @param {Object} params
 * * @returns {Array}
 */
export const getFileList = function (params) {
  return get('/resource/GetFileList',params)
};


/**
 * 获取检查项目分类
 * @returns {Array}
 */
export const noticeList = function (params) {
  return get('/notice/List',params)
};
/**
 * 获取检查项目分类
 * @returns {Array}
 */
export const checkCount = function (params) {
  return get('/check/Count',params)
};


/**
 * 通过域名获取平台配置
 * @param {Object} params
 * * @returns {Array}
 */
export const getByDomain = function (params={}) {
  return get('/platformConfig/GetByDomain', params)
};

/**
 * 获取检查标准列表
 * @param {Object} params
 * * @returns {Object}
 */
 export const getStandardData = function (params = {}) {
  return get('/standard/List', params)
};

/**
 * 获取检查标准详情
 * @param {Object} params
 * * @returns {Object}
 */
 export const getStandardInfo = function (params = {}) {
  return get('/standard/GetInfo', params)
};

/**
 * 通过检查资料
 * @param {Object} params
 * @returns {Object}
 */
export const getDoc = function (params = {}) {
  return get('/check/doc/ListByProject',params)
};

/**
 * 我完成的任务统计
 * @returns {Array}
 */
 export const workCheckCount = function (params) {
  return get('/work/CheckCount',params)
};

/**
 * 风险十大项统计
 * @returns {Array}
 */
 export const workRiskCount = function (params) {
  return get('/work/RiskCount',params)
};

/**
 * 风险十大项列表
 * @returns {Array}
 */
 export const dictListCategory = function (params) {
  return get('/dict/ListCategory',params)
};

/**
 * 获取所有检查任务列表
 * @returns {Array}
 */
 export const getCheckList = function (params) {
  return get('/check/List',params)
};

/**
 * 获取快捷信息
 * @returns {Array}
 */
 export const quickInfoList = function (params) {
  return get('/quickInfo/List',params)
};

/**
 * 获取附件
 * @param {Object} params
 * @returns {Object}
 */
 export const getFile = function (params) {
  return get('/resource/GetFileList', params)
};
