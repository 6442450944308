<template>
  <div class="topHeader" v-if="userInfo">
    <div class="avatar">
      <Poptip trigger="hover" placement="bottom-end">
        <div class="avatar-dropdown">
          <Avatar :src="`${config.baseURL}/resource/GetFile/${userInfo.expert.pictureId}?size=100`"  v-if="userInfo.expert.pictureId"/>
          <Avatar icon="ios-person" v-else/>
          {{ userInfo.expert.iname }}
          <Icon type="ios-arrow-down"/>
        </div>
        <div class="userInfoCard" slot="content">
          <div class="userInfo">
            <Avatar :src="`${config.baseURL}/resource/GetFile/${userInfo.expert.pictureId}?size=100`"  v-if="userInfo.expert.pictureId"/>
            <Avatar icon="ios-person" v-else/>
            <div class="info">
              <p class="name">{{ userInfo.expert.iname }}</p>
              <p class="brief">专家电话：{{ userInfo.expert.phone }}</p>
              <p class="brief">专家身份证：{{ userInfo.expert.identityCode }}</p>
            </div>
          </div>
          <div class="operation">
            <a class="item" href="javascript:;" @click="modFlag = true">
              <Icon type="ios-lock-outline"/>
              修改密码</a>
            <a class="item" href="javascript:;" @click="loginOut">
              <Icon type="ios-log-out"/>
              退出登录</a>
          </div>
        </div>
      </Poptip>
      <!-- 消息通知 -->
      <Poptip trigger="hover" placement="bottom-end" :events-enabled="true" @on-popper-show="reloadNum = new Date().getTime()">
        <div class="avatar-dropdown">
          <!-- <Badge :count="100"> -->
          <Icon type="ios-text" size="20"/>
          <!-- </Badge> -->
        </div>
        <div slot="content" class="myNews" style="width: 350px;min-height: 200px;">
          <articleConsultation :type="1" :key="reloadNum"></articleConsultation>
        </div>
      </Poptip>
    </div>
    <!-- 其他菜单 -->
    <div class="otherMenu">
      <a href="https://youshi-cdn.oss-cn-hangzhou.aliyuncs.com/doc/%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C/%E5%AE%89%E8%B4%A3%E9%99%A9%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0%E4%B8%93%E5%AE%B6%E7%AB%AF-%E7%94%A8%E6%88%B7%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf" target="_blank" class="help">
        <Icon type="md-bookmarks" />
        帮助手册
      </a>
    </div>
    <ys-modal width="500" :closable="!weekPassWord" v-model="modFlag" title="修改密码">
      <modPassword :weekPassWord="weekPassWord"></modPassword>
    </ys-modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import modPassword from '_c/header/ModPwd.vue'
import {clone} from 'ys-admin'
import articleConsultation from "@/views/home/articleConsultation/index";

export default {
  name: "top-header",
  computed: {
    ...mapGetters(['config', 'userInfo']),
    orgName() {
      const orgList = this.userInfo['orgs'];
      return Array.isArray(orgList) && orgList.length > 0 ? (orgList.filter(item => item.id === this.userInfo.org_id)[0].iname) : '';
    },
    weekPassWord() {
      return sessionStorage.getItem('weekPassWord')==='true'
    },
  },
  components: {modPassword,articleConsultation},
  data() {
    return {
      modFlag: false,
      reloadNum: 0,
    }
  },
  mounted() {
    if (this.weekPassWord) this.modFlag = true;
  },
  methods: {
    ...mapMutations(['setToken', 'setUserInfo']),
    changeOrg(index) {
      let userInfo = clone(this.userInfo);
      userInfo.org_id = this.userInfo['orgs'][index].id;
      this.setUserInfo(userInfo);
      window.location.reload();
    },
    loginOut() {
      this.$Modal.confirm({
        title: '提示',
        content: '确定要退出系统么？',
        onOk: () => {
          this.setToken();
          this.setUserInfo();
          sessionStorage.clear();
          this.$router.push({name: 'login'})
        }
      });

    },

    set(name) {
      switch (name) {
        case 'modPass':
          this.modFlag = true;
          break;
        case 'loginOut':
          this.$Modal.confirm({
            title: '提示',
            content: '确定要退出系统么？',
            onOk: () => {
              this.loginOut();
            }
          });
          break;
      }
    }
  }
}
</script>

<style scoped lang="less">
.config {
  display: flex;
}

.topHeader {
  line-height: 45px;
  display: flex;
  flex: 1;
  flex-direction: row-reverse;

  .otherMenu {
    .help {
      display: block;
      margin: 0 10px;
    }
  }

  .avatar {
    .avatar-dropdown {
      padding: 0 10px;
      cursor: pointer;
      height: 45px;
    }
  }
}


</style>