import {mapGetters} from 'vuex'

export default {
  name: 'detailMixins',
  props: {
    tabName: {type: String, default: '1'},
    midData: {type: Array, default: () => []},
    mid: {type: [String, Number], default: null},
  },
  computed: {
    ...mapGetters(['token', 'baseData', 'height','config']),
    detailIcon() {
      return this.$router.currentRoute.meta.icon || 'ios-help-circle';
    },
    pageListHeight() {
      return this.$router.currentRoute.params.id ? this.height - 378 : this.height - 283
    },
    detailHeight() {
      return this.height - 190
    },
    detailID() {
      return this.mid || this.paramsID;
    },
  },
  data() {
    return {
      detail: {},
      loading: false,
      tab: '1',
      paramsID: this.$router.currentRoute.params.id
    }
  },
  watch: {
    'detailID': function (data) {
      let tabIndex = this.tab;
      this.tab = '0';
      if (data) {
        this.getDetail();
        this.$nextTick(() => {
          this.tab = tabIndex;
        })
      }
    },
    '$route': function (val) {
      if (val.params.id) {
        this.paramsID = this.$router.currentRoute.params.id;
        this.getDetail();
      }
    }
  },
  mounted() {
    this.tab = this.tabName || '1';
  },
  methods: {
    setTagName(name) {
      if (this.paramsID) {
        let path = this.$router.currentRoute.path;
        let topBarNavList = JSON.parse(localStorage.getItem('pageTabList'));
        /*判断当前path是否已经存在topBarNavList里*/
        topBarNavList.forEach((item) => {
          if (item.path === path) {
            item.meta.title = name;
          }
        })
        localStorage.setItem('pageTabList', JSON.stringify(topBarNavList))
      }
    }
  }
};
