import Vue from 'vue'
import Vuex from 'vuex'
import {get, clone, listToTree} from 'ys-admin'
import config from '@/config'
import router from "@/router";
import { resetRouter } from "@/router";

/*默认设置的路由，如果接口未返回路由则启用*/
const defaultRouters = [
  {path: '/main/workStation', name: 'home', meta: {title: '工作台', index: '2', home: true, icon: 'md-easel'}, component: () => import('../views/home/index.vue')},
  {path: '/main/specialTask', name: 'specialTask', meta: {title: '专项任务', index: '3', icon: 'md-locate'}, component: () => import('../views/specialTask/index.vue')},
  {path: '/main/task', name: 'task', meta: {title: '安责险任务', index: '4', icon: 'md-clipboard'}, component: () => import('../views/task/index.vue')},
  {path: '/main/rectifications', name: 'rectifications', meta: {title: '整改单', index: '5', icon: 'logo-buffer'}, component: () => import('../views/rectifications/index')},
  {
    path: '/main/rectifications/detail/:id',
    name: 'rectificationsDetail',
    meta: {title: '整改单详情', index: '5-1', icon: 'logo-buffer', hideInMenu: true},
    component: () => import('../views/rectifications/detail.vue')
  },
  { 
    path: '/main/problems',
    name: 'problemsList',
    meta: {title: '风险汇总', index: '9', icon: 'ios-alert'},
    component: () => import('../views/problems/index')
  },
  {
    path: '/main/problems/detail/:id',
    name: 'problemsDetail',
    meta: {title: '风险详情', index: '9-1', icon: 'ios-alert', hideInMenu: true},
    component: () => import('../views/problems/detail.vue')
  },
  {path: '/main/report', name: 'report', meta: {title: '报告库', index: '7', icon: 'md-copy'}, component: () => import('../views/report/index')},
  {path: '/main/signList', name: 'signList', meta: {title: '打卡记录', index: '6', icon: 'ios-navigate-outline'}, component: () => import('../views/sign/index')},
  {
    path: '/main/role',
    name: 'role',
    meta: {title: "角色管理", index: "8", icon: "ios-person", hideInMenu: true},
    component: () => import('../views/role/index.vue')
  },
  {
    path: "/main/myMessageSet",
    name: "myMessageSet",
    meta: {title: '我的信息', index: '17', icon: 'md-settings'},
    component: () => import('../views/myMessageSet/index'),
  },
  //路由不要写在这个后面
  {
    path: '/main/*', name: 'main-404', meta: {icon: 'ios-appstore', title: '机构成员详情', index: '99', hideInMenu: true}, component: () => import('../components/404')
  },
]

/**
 * deepRouterMap 处理接口返回的路由数据
 * @routerMap {array}
 * @return {array} 处理后的数组
 **/
const deepRouterMap = (routerMap = []) => {
  return routerMap.map((item) => {
    if (item.children && item.children.length > 0) {
      item.component = (resolve) => require(['@/views/routerContainer'], resolve);
      deepRouterMap(item.children, item.name);
    } else {
      if (item.file) item.component = (resolve) => require(['@/views/' + item.file], resolve);
    }
    item.meta = {title: item.title, home: item.home ? true : false, hideInMenu: item.hideInMenu ? true : false, index: item.index, icon: item.icon};
    return item
  })
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuList: [],
    config: sessionStorage.getItem('config') ? JSON.parse(String(sessionStorage.getItem('config'))) : {},
    height: null,
    userInfo: sessionStorage.getItem('userInfo') ? JSON.parse(String(sessionStorage.getItem('userInfo'))) : {},
    token: String(sessionStorage.getItem('token') || ''),
    baseData: {}, /*基础数据项，调用方式this.baseData['项目类型']*/
  },
  mutations: {
    setHeight(states, data) {
      states.height = data;
    },
    setMenuList(states, data) {
      resetRouter(); // 重新添加之前先重置一下路由
      if (data.length > 0) {
        const menuList = listToTree(data, {parentKey:'parentId',topLevelVal: 0});
        deepRouterMap(clone(menuList)).forEach(item => {
          router.addRoute(config.routerParent, item);
        })
        router.addRoute(config.routerParent, {path: '/main/*', name: '404', meta: {icon: 'ios-appstore', title: '404', index: '99', hideInMenu: true}, component: () => import('../components/404')})
        /*追加404页面路由*/
      } else {/*接口未返回路由数据则启用默认路由，看前面代码*/
        defaultRouters.forEach(item => {
          router.addRoute(config.routerParent, item);
        })
      }
      let routes = clone(router.getRoutes()).map(({...item}) => {
        item.parentName = item.parent ? item.parent.name : '0'
        return item
      });
      states.menuList = listToTree(routes, {parentKey: 'parentName', sonKey: 'name', topLevelVal: '0'}).filter(item => item.name === config.routerParent)[0].children;
    },
    setToken(states, data) {
      sessionStorage.setItem('token', data);
      states.token = data;
    },
    setConfig(states, data) {
      states.config = {...states.config, ...data};
      sessionStorage.setItem('config', JSON.stringify(states.config));
    },
    setUserInfo(states, data) {
      if (!data) return;
      let userInfo = JSON.parse(JSON.stringify(data));
      if (!userInfo.org_id) {
        if (userInfo.orgs && userInfo.orgs.length >= 1) {
          userInfo.org_id = userInfo.orgs[0].id;
        }
      }
      sessionStorage.setItem('orgId', userInfo.org_id);
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      states.userInfo = userInfo;
    },
    setBaseData(states, data) {
      states.baseData = data;
    },
  },
  getters: {
    menuList: (state) => state.menuList,
    config: (state) => state.config,
    height: (state) => state.height,
    token: (state) => state.token,
    baseData: (state) => state.baseData,
    userInfo: (state) => state.userInfo,
  },
  actions: {
    // 获取系统菜单栏
    getMenuList({commit}) {
      return new Promise((resolve, reject) => {
        get("/menu/OrgMenuList", {o_type: 5}).then((res) => {
          if (res.code === 200) {
            commit('setMenuList', res.data || []);
            resolve();
          } else {
            commit('setMenuList', []);
            reject();
          }
        })
      })
    },
  }
})
