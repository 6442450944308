import ViewUI, {LoadingBar, Notice, Modal, Tooltip, Message} from 'view-design'
import ysCell from './components/ys-cell/index.vue'
import ysCellGroup from './components/ys-cell/ys-cell-goup.vue'
import ysCellMenu from './components/ys-cell-menu/index.vue'
import ysPaper from './components/ys-paper/index.vue'
import ysCellMenuGroup from './components/ys-cell-menu/group.vue'
import ysContainer from './components/ys-container/ys-container.vue'
import ysDatePicker from './components/ys-date-picker/index.vue'
import ysDetail from './components/ys-detail/index.vue'
import ysDrawer from './components/ys-drawer/index.vue'
import ysModal from './components/ys-modal/index.vue'
import ysPageListExport from './components/ys-page-list/export.vue'
import ysPageListColumn from './components/ys-page-list/column.vue'
import ysPageList from './components/ys-page-list/index.vue'
import ysRegion from './components/ys-region/index.vue'
import ysBusiness from './components/ys-business/index.vue'
import ysBusinessInfo from './components/ys-business/info.vue'
import ysUpload from './components/ys-upload/index.vue'
import ysFileList from './components/ys-file-list/index.vue'
import ysImage from './components/ys-image/index.vue'
import ysTree from './components/ys-tree/index.vue'
import ysMap from './components/ys-map/index.vue'
import ysEmpty from './components/ys-empty/index.vue'
import ysEnter from './components/ys-enter/index.vue'
import ysResource from './components/ys-resource/index.vue'
import ysExamine from './components/ys-examine/index.vue'
import ysIconSelect from './components/ys-icon-select/index.vue'
import {clone, getBaseDataName, getBaseData, listToTree, formatDate, getRecorderAryByDeep, uuid} from "./lib";
import * as reg from "./lib/reg";
import axios, {put, post, del, get, all} from './request'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import Print from 'vue-print-nb'
import ysDraggable from "vuedraggable";

const config = localStorage.getItem('set') ? JSON.parse(localStorage.getItem('set')) : {};
import("./assets/theme/" + (config.theme || 'light') + "/" + (config.color || '2d8cf0') + "/index.less");
let color = {
  dark: {backgroundColor: '#141414', borderBaseColor: '#303030', componentBackground: '#1d1d1d', textColor: 'rgba(255,255,255,0.65)', primaryColor: '#' + (config.color || '2d8cf0')},
  light: {backgroundColor: '#f7f7f7', borderBaseColor: '#dcdee2', componentBackground: '#fff', textColor: '#515a6e', primaryColor: '#' + (config.color || '2d8cf0')}
};
const components = {
      ysCell,ysMap, ysResource, ysExamine, ysBusinessInfo,ysBusiness, ysTree, ysDraggable, ysCellGroup, ysEnter, ysPageListExport, ysPaper, ysFileList, ysPageListColumn, ysCellMenu, ysCellMenuGroup, ysContainer, ysDatePicker,
    ysDetail, ysDrawer, ysModal, ysPageList, ysRegion, ysUpload, ysImage, ysIconSelect, ysEmpty
};
const iview = {
  ...components
};
window.regionData = null;

const install = function (Vue, opts = {}) {
  if (install['installed']) return;
  Object.keys(iview).forEach(key => {
    Vue.component(key, iview[key]);
  });
  axios.defaults.baseURL = opts.axiosBaseUrl || '';
  axios.headers = opts.axiosHeaders || [];
  Vue.use(Viewer)
  Vue.use(Print);
  Viewer.setDefaults({
    Options: {zIndex: 6000, 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source'}
  })
  Vue.use(ViewUI, opts['iviewConfig'] || {});
  Vue.prototype.$enterNav = (opts.enterNav || []).length === 0 ? ['gov', 'ins', 'ent', 'ser', 'ter'] : opts.enterNav;
  Vue.prototype.$platFormType = opts.platFormType == undefined ? 6 : opts.platFormType;
  axios.defaults.pendingFlag = opts.pendingFlag || true;
  Vue.prototype.$getFileUrl = opts.getFileUrl;
  Vue.prototype.$axiosBaseUrl = opts.axiosBaseUrl;
  Vue.prototype.$clone = clone;
  Vue.prototype.$listToTree = listToTree;
  Vue.prototype.$uuid = uuid;
  Vue.prototype.$getBaseDataName = getBaseDataName;
  Vue.prototype.$getBaseData = getBaseData;
  Vue.prototype.$getRecorderAryByDeep = getRecorderAryByDeep;
  Vue.prototype.$formatDate = formatDate;
  Vue.prototype.$reg = reg;
  Vue.prototype.$Notice = Notice;
  Vue.prototype.$Modal = Modal;
  Vue.prototype.$Message = Message;
  Vue.prototype.$LoadingBar = LoadingBar;
  Vue.prototype.$get = get;
  Vue.prototype.$post = post;
  Vue.prototype.$put = put;
  Vue.prototype.$del = del;
  Vue.prototype.$all = all;
  Vue.prototype.$axios = axios;
};
get('/web/resource/Area').then(res => {
  window.regionData = res.data
});
//**判断是否是低版本浏览器访问本站，是则提示！//
const theUA = window.navigator.userAgent.toLowerCase();
if ((theUA.match(/msie\s\d+/) && theUA.match(/msie\s\d+/)[0]) || (theUA.match(/trident\s?\d+/) && theUA.match(/trident\s?\d+/)[0])) {
  const ieVersion = theUA.match(/msie\s\d+/)[0].match(/\d+/)[0] || theUA.match(/trident\s?\d+/)[0];
  if (ieVersion < 10) {
    const str = "你的浏览器版本太低了,请升级您的浏览器";
    const str2 = "<h2 style='font-weight:900;padding:10px 0;'>推荐使用：<a href='https://www.baidu.com/s?ie=UTF-8&wd=%E8%B0%B7%E6%AD%8C%E6%B5%8F%E8%A7%88%E5%99%A8' target='_blank' style='color:#ffffff;'>谷歌</a>,"
        + "<a href='https://www.baidu.com/s?ie=UTF-8&wd=%E7%81%AB%E7%8B%90%E6%B5%8F%E8%A7%88%E5%99%A8' target='_blank' style='color:#ffffff;'>火狐</a>,"
        + "或其他双核极速模式</h2>";
    document.writeln("<pre style='margin:0;font-size:25px;text-align:center;color:#fff;background-color:rgba(0,0,0,0.8); height:100%;border:0;position:fixed;top:0;left:0;width:100%;z-index:1234'>" +
        "<h2 style='padding-top:200px;margin:0'><strong>" + str + "<br/></strong></h2><h2>" +
        str2 + "</h2><h2 style='margin:0'><strong>如果您的使用的是360、搜狗、QQ等双核浏览器，请在最顶部切换到极速模式访问<br/></strong></h2></pre>");
    document.execCommand("Stop");
  }
}
// auto install
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}
const ysAdmin = {
  colorTheme: config.theme || 'light',
  color: color[config.theme || 'light'],
  version: process.env.VERSION,
  install,
  Tooltip: Tooltip,
  Notice: Notice,
  LoadingBar: LoadingBar,
  ...components
}
// 导出
export default ysAdmin;
export * from './request';
export * from "./lib";
export {clearPending} from "./request/axios-function";